var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"header"}},[_c('v-app-bar',{staticClass:"rounded",attrs:{"color":"blue darken-2","flat":"","dark":""}},[_c('h2',{staticClass:"font-weight-medium"},[_vm._v("Gráficos")])])],1),_c('div',{attrs:{"id":"body"}},[_c('v-card',{staticClass:"fill-height",attrs:{"height":"810","flat":""}},[_c('v-card-title',{staticClass:"font-weight-medium headline"},[_vm._v(" Proyecto: "+_vm._s(_vm.nombre_proyecto)+" ")]),_c('v-card-subtitle',{staticClass:"font-weight-medium title mt-0"},[_vm._v(" Datos disponibles "),(_vm.data_total.length > 1)?_c('div',{staticClass:"mt-2"},[_c('div',[_c('v-slide-group',{model:{value:(_vm.selected_option),callback:function ($$v) {_vm.selected_option=$$v},expression:"selected_option"}},_vm._l((_vm.categorias),function(item,i){return _c('v-slide-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"dark":"","color":active ? 'indigo darken-2' : 'blue darken-1'},on:{"click":function($event){return _vm.onCardClick(item,i)}}},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,true)})}),1)],1)]):_vm._e(),(_vm.data_total.length === 1)?_c('div',[_vm._v(" Solo un tipo de data total ")]):_vm._e()]),_c('v-card-text',[_c('h3',{staticClass:"font-weight-medium"},[_vm._v(" Seleccione un gráfico para cada dato disponible ")]),_vm._l((_vm.data_charts),function(dato_chart,n){return _c('div',{key:n,staticClass:"mt-5"},[(dato_chart.selected !== 'ranking' && 
                  dato_chart.selected != 'simple_ranking' &&
                  dato_chart.selected != 'card_ranking' &&
                  dato_chart.selected != 'no_chart' &&
                  dato_chart.selected != 'card_list' &&
                  dato_chart.selected != 'stack_column')?_c('v-alert',{attrs:{"border":"left","dark":"","color":"indigo darken-1","elevation":"2","dense":"","height":"60"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"mt-1",staticStyle:{"display":"inline-block"}},[_c('v-icon',{staticStyle:{"margin-top":"-6px"}},[_vm._v(" mdi-file ")]),_c('span',{staticClass:"ml-4 font-weight-medium title"},[_vm._v(" "+_vm._s(dato_chart.variable)+" ")])],1)]),_c('v-col',{staticStyle:{"margin-top":"-15px"},attrs:{"cols":"8"}},[_c('v-radio-group',{attrs:{"row":"","mandatory":""},model:{value:(dato_chart.selected),callback:function ($$v) {_vm.$set(dato_chart, "selected", $$v)},expression:"dato_chart.selected"}},_vm._l((dato_chart.charts),function(option_chart,i){return _c('v-radio',{key:i,staticClass:"px-3",attrs:{"value":option_chart},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('v-icon',{staticStyle:{"margin-top":"-5px"},attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.charts[option_chart].icon)+" ")]),_c('strong',{staticClass:"ml-2 title"},[_vm._v(" "+_vm._s(_vm.charts[option_chart].text)+" ")])],1)]},proxy:true}],null,true)})}),1)],1)],1)],1):_vm._e()],1)}),(_vm.loading)?_c('div',[_c('v-progress-circular',{staticClass:"mt-2 ml-2",attrs:{"indeterminate":"","color":"teal","size":50}})],1):_vm._e(),(_vm.loading === false)?_c('div',[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"teal","dark":""},on:{"click":function($event){return _vm.generarDashboards(_vm.new_proyectos)}}},[_vm._v(" Generar Dashboard ")])],1):_vm._e()],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }